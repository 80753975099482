import { useQuery } from '@tanstack/vue-query';
import { validatorFactory, validatorFactoryArray } from '@laam/lib/validator';
import * as Sentry from '@sentry/vue';

import {
	type ProductHandlingDate,
	type VariantHandlingDate,
	type SizeChartData,
	productHandlingDate,
	variantHandlingDate,
	productCarouselSchema,
	type ProductCarousel,
} from '~/types/Product';
import { productSchema, type ProductSchema } from '@laam/cms-shared';

const productSchemaValidator = validatorFactory<ProductSchema>(productSchema);
const productCarouselSchemaValidator = validatorFactoryArray<ProductCarousel>(
	productCarouselSchema,
);

export const useFetchProductByHandle = async (
	handle: string,
	enabled: MaybeRefOrGetter<boolean>,
	loganToken: Ref<string>,
) => {
	const productByHandleResponse = useQuery({
		queryKey: ['product', handle, 'loganToken', loganToken],
		enabled,
		queryFn: async () => {
			const api = createApiInstance();
			let response;
			try {
				response = await api.get(`/v1/products?handle=${handle}`);
			} catch (error) {
				console.log('error', error);
				throw error;
			}
			const data = await response.data;
			if (response.status === 200) {
				try {
					// TODO: Replace this with the actual API endpoint
					const response2 = await fetch(
						`https://api.laam.pk/product_handling_timelines/?product_id=${data.shopify_id}`,
					);

					const data2 = await response2.json();
					const body = {
						...data,
						product_handling_date: data2.handling_date,
					};

					const validatedData = productSchemaValidator.verify(body);

					return validatedData;
				} catch (e) {
					const error = new Error('Failed to verify product data');
					console.error('Failed to verify product data', e);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: e,
						});
						return scope;
					});
					throw error;
				}
			}

			const error = new Error('Failed to fetch product data');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', data);
				return scope;
			});
			throw error;
		},
	});
	return productByHandleResponse;
};

const productHandlingDateValidator =
	validatorFactory<ProductHandlingDate>(productHandlingDate);

export const useFetchProductHandlingDate = async (productId: number) => {
	const { data, isLoading, suspense, error } = useQuery({
		queryKey: ['productId', productId],

		queryFn: async () => {
			const api = createApiInstance();
			const response = await api.get(
				`https://api.laam.pk/product_handling_timelines/?product_id=${productId}`,
			);

			if (response.status === 200) {
				const body = response.data;

				try {
					const validatedData = productHandlingDateValidator.verify(body);

					return validatedData;
				} catch (e) {
					const error = new Error(
						'Failed to verify product handling date data',
					);
					console.error('Failed to verify product handling date data', e);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: e,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Failed to fetch product handling date data');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { data, isLoading, suspense, error };
};

const variantHandlingDateValidator =
	validatorFactory<VariantHandlingDate>(variantHandlingDate);

export async function getVariantHandlingDate(variantId: number) {
	if (variantId) {
		const response = await fetch(
			`https://api.laam.pk/handling_timelines/?product_variant_id=${variantId}`,
		);

		if (response.status === 200) {
			const body = await response.json();

			try {
				const validatedData = variantHandlingDateValidator.verify(body);

				return validatedData;
			} catch (e) {
				const error = new Error('Failed to verify variant handling date data');
				console.error('Failed to verify variant handling date data', e);
				Sentry.captureException(error, (scope) => {
					scope.setContext('errors', {
						error: e,
					});
					return scope;
				});
				throw error;
			}
		}
		const error = new Error('Failed to fetch variant handling date data');
		const data = await response.json();
		Sentry.captureException(error, (scope) => {
			scope.setContext('response', data);
			return scope;
		});
		throw error;
	} else return null;
}

// FIXME: use validatory to type me
export const useFetchSizeChart = async (
	sizeChartId: number,
	enabled: globalThis.Ref<boolean>,
) => {
	const result = useQuery({
		queryKey: ['sizeChartId', sizeChartId],

		queryFn: async () => {
			const api = createApiInstance();
			const response = await api.get(
				`/product/size-chart-v2?size_chart_id=${sizeChartId}`,
			);

			if (response.status === 200) {
				const sizeChartResponse = response.data.msg.sizechart.sizechart_data;

				const sizeChartData: SizeChartData = {};
				sizeChartResponse['headings'].forEach((element: string) => {
					if (sizeChartResponse['shirt_head'] !== null) {
						sizeChartData[element.toLowerCase()] = {
							heading: sizeChartResponse['shirt_head'],
							data: sizeChartResponse['shirt'],
						};
						sizeChartResponse['shirt_head'] = null;
					} else if (sizeChartResponse['pant_head'] !== null) {
						sizeChartData[element.toLowerCase()] = {
							heading: sizeChartResponse['pant_head'],
							data: sizeChartResponse['pant'],
						};
						sizeChartResponse['pant_head'] = null;
					} else if (sizeChartResponse['third_head'] !== null) {
						sizeChartData[element.toLowerCase()] = {
							heading: sizeChartResponse['third_head'],
							data: sizeChartResponse['third'],
						};
						sizeChartResponse['third_head'] = null;
					}
				});

				return sizeChartData;
			}
			const error = new Error('Failed to fetch size chart data');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
		enabled,
	});

	return result;
};

export const useFetchProductCarousels = (
	carousel_type: string,
	product_id: number,
	drop_handle: string,
) => {
	const enabled = computed(() => !!carousel_type && !!product_id);
	const { data, isLoading, suspense, error } = useQuery({
		queryKey: ['product_carousel', carousel_type, product_id],
		retry: false,
		refetchOnWindowFocus: false,
		enabled,

		queryFn: async () => {
			let param = '';

			if (carousel_type === 'more-from-collection') {
				param = `product_id=${product_id}&handle=${drop_handle}`;
			} else {
				param = `product_id=${product_id}`;
			}
			const api = createApiInstance();
			const response = await api.get(
				`/v1/products/carousel?carousel_type=${carousel_type}&${param}`,
			);
			if (response.data === null) {
				return [];
			}
			if (response.status === 200 && response.data !== null) {
				try {
					const validatedData = productCarouselSchemaValidator.verify(
						response.data,
					);
					return validatedData;
				} catch (e) {
					const error = new Error('Failed to verify product carousel data');
					console.error('Failed to verify product carousel data', e);
					Sentry.captureException(error, (scope) => {
						scope.setContext('errors', {
							error: e,
						});
						return scope;
					});
					throw error;
				}
			}
			const error = new Error('Failed to fetch product carousel data');
			Sentry.captureException(error, (scope) => {
				scope.setContext('response', response.data);
				return scope;
			});
			throw error;
		},
	});
	return { data, isLoading, suspense, error };
};
