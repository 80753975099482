<script setup lang="ts">
import { PhCaretRight, PhInfo, PhLockSimple } from '@phosphor-icons/vue';
import { useLoganStore } from '~/stores/logan.ts';
import {
	Dialog,
	DialogContent,
	Drawer,
	DrawerContent,
	DrawerTrigger,
} from '@laam/ui/base';
import BackgroundLines from '~/icons/BackgroundLines.vue';
import { Separator } from 'radix-vue';
import CashIcon from '~/icons/CashIcon.vue';
import HowRewardsWork from '~/components/rewards/HowRewardsWork.vue';
import { createReusableTemplate } from '@vueuse/core';
import { useCurrency } from '~/stores/currency.ts';

const { $eventClient } = useNuxtApp();

const LoganStore = useLoganStore();
const CurrencyStore = useCurrency();

const showRewardsPopup = ref<boolean>(false);
// const registrationRewardPoints = ref<number>(0);

const productRewardsAmount: Ref<number> = inject('productRewardsAmount')!;
const checkoutLocalization: Ref<boolean> = inject('checkoutLocalization')!;

const handleRewardsPopup = () => {
	showRewardsPopup.value = !showRewardsPopup.value;
};
// const { data: signupRewardsData } = useSignupRewardsQuery();

const [UseTemplate, RewardFaqContent] = createReusableTemplate();

// watch(
// 	signupRewardsData,
// 	(newVal: { amount: number } | undefined) => {
// 		if (newVal && newVal.amount) {
// 			registrationRewardPoints.value = newVal.amount;
// 		}
// 	},
// 	{
// 		immediate: true,
// 	},
// );
</script>

<template>
	<ClientOnly>
		<UseTemplate>
			<BackgroundLines
				class="absolute inset-0 m-auto bottom-[80%] left-[-85%] z-[-1]"
			/>
			<CashIcon
				class="absolute inset-0 m-auto bottom-[80%] left-[-85%] z-[-1]"
			/>
			<div class="gap-3xl mt-12xl flex flex-col">
				<HowRewardsWork />
				<Separator class="w-full h-[1px] bg-gray-100" />
				<RewardsFaqs />
			</div>
		</UseTemplate>
		<div class="px-xl gap-3xl flex flex-col">
			<div class="flex items-center justify-between">
				<div class="text-base font-semibold">Rewards</div>
				<PhInfo
					v-if="!isMobileView()"
					size="20"
					class="focus:outline-none text-gray-400 cursor-pointer"
					@click.prevent="() => (showRewardsPopup = true)"
				/>

				<Drawer v-else>
					<DrawerTrigger>
						<PhInfo
							size="20"
							class="focus:outline-none text-gray-400 cursor-pointer"
						/>
					</DrawerTrigger>
					<DrawerContent class="max-h-[80vh] h-full overflow-hidden">
						<RewardFaqContent />
					</DrawerContent>
				</Drawer>
			</div>

			<div
				class="flex justify-between cursor-pointer"
				@click.prevent="
					() => {
						$eventClient.sendEvent('rewards-register-click', {
							path: 'product detail card',
						});
						LoganStore.showLoginSheet = true;
					}
				"
			>
				<div class="gap-xl flex items-center">
					<PhLockSimple class="text-gray-600" size="20" />
					<div class="text-sm font-normal">
						Sign in and get up to
						<span v-if="checkoutLocalization" class="font-semibold">
							{{ productRewardsAmount.toLocaleString('en-US') }} points
						</span>
						<span v-else class="font-semibold">
							{{ CurrencyStore.currencySymbol }}
							{{ CurrencyStore.getFormattedPrice(productRewardsAmount) }}
						</span>
						in rewards on this purchase
					</div>
				</div>

				<PhCaretRight class="text-gray-600" size="20" />
			</div>
		</div>

		<Dialog :open="showRewardsPopup" @update:open="handleRewardsPopup">
			<DialogContent
				class="max-w-[640px] max-h-[680px] w-full rounded-xl overflow-y-auto"
			>
				<RewardFaqContent />
			</DialogContent>
		</Dialog>
	</ClientOnly>
</template>
