<script lang="ts" setup>
import { DrawerOverlay } from 'vaul-vue';
import type { DialogOverlayProps } from 'radix-vue';
import { type HtmlHTMLAttributes, computed } from 'vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	DialogOverlayProps & { class?: HtmlHTMLAttributes['class'] }
>();

const delegatedProps = computed(() => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { class: _, ...delegated } = props;

	return delegated;
});
</script>

<template>
	<DrawerOverlay
		v-bind="delegatedProps"
		:class="
			cn(
				'drawer-overlay fixed inset-none z-50 bg-black/80 top-none !h-full !w-full',
				props.class,
			)
		"
	/>
</template>
