import { type Static, Type } from '@sinclair/typebox';

export type ProductSchema = Static<typeof productSchema>;
export const productSchema = Type.Object({
	brand: Type.String(),
	category: Type.String(),
	compare_at_price: Type.Number(),
	description: Type.String(),
	additional_description: Type.String(),
	drop_handle: Type.String(),
	external_product_id: Type.String(),
	handle: Type.String(),
	image: Type.Object({
		shopify_id: Type.Number(),
		alt: Type.String(),
		src: Type.String(),
		width: Type.Number(),
		height: Type.Number(),
	}),
	media: Type.Array(
		Type.Object({
			shopify_id: Type.Number(),
			alt: Type.String(),
			src: Type.String(),
			width: Type.Number(),
			height: Type.Number(),
		}),
	),
	meta: Type.Object({
		cta: Type.String(),
		cart_item_limit: Type.Number(),
	}),
	reseller_price: Type.Optional(Type.String()),
	options: Type.Array(
		Type.Object({
			values: Type.Array(Type.String()),
			name: Type.String(),
			position: Type.Number(),
		}),
	),
	rewards: Type.Optional(Type.String()),
	partner: Type.String(),
	price: Type.Number(),
	product_handling_date: Type.Optional(Type.String()),
	size_chart_id: Type.Number(),
	size_chart_name: Type.String(),
	shopify_id: Type.Number(),
	status: Type.String(),
	stock_status: Type.String(),
	tags: Type.Array(Type.String()),
	total_inventory_quantity: Type.Number(),
	title: Type.String(),
	valhalla_score: Type.Number(),
	variants: Type.Array(
		Type.Object({
			shopify_id: Type.Number(),
			price: Type.String(),
			compare_at_price: Type.String(),
			inventory_quantity: Type.Number(),
			stock_status: Type.String(),
			reseller_price: Type.Optional(Type.String()),
			weight_unit: Type.String(),
			weight: Type.Number(),
			sku: Type.String(),
			title: Type.String(),
			variant_handling_date: Type.Optional(Type.String()),
			rewards: Type.Optional(Type.String()),
		}),
	),
	rating: Type.Optional(
		Type.Object({
			total_ratings: Type.Number(),
			average_rating: Type.Number(),
			rating_1_count: Type.Number(),
			rating_2_count: Type.Number(),
			rating_3_count: Type.Number(),
			rating_4_count: Type.Number(),
			rating_5_count: Type.Number(),
		}),
	),
	brand_handle: Type.Optional(Type.String()),
	brand_logo: Type.Optional(Type.String()),
	attributes: Type.Object({
		'bottom-fabrics': Type.String(),
		'color-type': Type.String(),
		'dupatta-fabrics': Type.String(),
		fabric: Type.String(),
		'number-of-pieces': Type.String(),
		'product-type': Type.String(),
		season: Type.String(),
		'shirt-fabrics': Type.String(),
		'trouser-fabrics': Type.String(),
	}),
	node_l1: Type.String(),
	node_l2: Type.String(),
	node_l3: Type.String(),
	node_l4: Type.String(),
});
