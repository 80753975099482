<script lang="ts" setup>
import { DrawerContent, DrawerPortal } from 'vaul-vue';
import type { DialogContentEmits, DialogContentProps } from 'radix-vue';
import { useForwardPropsEmits } from 'radix-vue';
import type { HtmlHTMLAttributes } from 'vue';
import DrawerOverlay from './DrawerOverlay.vue';
import { cn } from '@laam/lib/utils';

const props = defineProps<
	DialogContentProps & {
		class?: HtmlHTMLAttributes['class'];
		slotClass?: HtmlHTMLAttributes['class'];
	}
>();
const emits = defineEmits<DialogContentEmits>();

const forwarded = useForwardPropsEmits(props, emits);
</script>

<template>
	<DrawerPortal>
		<DrawerOverlay class="drawer_overlay" />
		<DrawerContent
			v-bind="forwarded"
			:class="
				cn(
					'drawer-content fixed inset-x-none bottom-0 z-50 flex flex-col rounded-t-[10px] border border-gray-200 bg-white ',
					props.class,
				)
			"
		>
			<div class="mx-auto mt-3xl h-md w-[100px] rounded-full bg-gray-100" />
			<div
				:class="
					cn('max-h-[calc(100vh-80px)] overflow-auto p-3xl', props.slotClass)
				"
			>
				<slot />
			</div>
		</DrawerContent>
	</DrawerPortal>
</template>
